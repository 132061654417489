import config from "../../config.json"

export default {
	namespaced: true,

	state: {},

	actions: {

		register: (_, payload) => {
			return new Promise((resolve, reject) => {

				fetch(config.api.url + "/v1.0/auth/register", {
					method: "POST",
					body: JSON.stringify(payload)
				}).then(Response => Response.json()).then(Response => {
					if ( Response.status === "success" ) {
						localStorage.setItem("token", Response.data.token)
						resolve(true)
					} else {
						reject(Response)
					}
				}).catch(e => reject(e))

			})
		},

		login: (_, payload) => {
			return new Promise((resolve, reject) => {

				fetch(config.api.url + "/v1.0/auth/login", {
					method: "POST",
					body: JSON.stringify(payload)
				}).then(Response => Response.json()).then(Response => {
					if ( Response.status === "success" ) {
						localStorage.setItem("token", Response.data.token)
						resolve(true)
					} else {
						reject(Response)
					}
				}).catch(error => reject(error))

			})
		}

	}
}
