import { createRouter, createWebHistory } from "vue-router"
import isAuthenticated from "../middleware/auth"

const routes = [
	{
		path: "/",
		name: "Root",
		beforeEnter: isAuthenticated
	},
	{
		path: "/login",
		name: "Login",
		component: () => import(/* webpackChunkName: "login" */ "../views/Login")
	},
	{
		path: "/logout",
		name: "Logout",
		component: () => import(/* webpackChunkName: "logout" */ "../views/Logout"),
		beforeEnter: isAuthenticated
	},
	{
		path: "/register",
		name: "Register",
		component: () => import(/* webpackChunkName: "register" */ "../views/Register")
	},
	{
		path: "/items",
		name: "Items",
		component: () => import(/* webpackChunkName: "items" */ "../views/Items"),
		beforeEnter: isAuthenticated
	},
	{
		path: "/orders",
		name: "Orders",
		component: () => import(/* webpackChunkName: "orders" */ "../views/Orders"),
		beforeEnter: isAuthenticated
	},
	{
		path: "/order/:ref",
		name: "Order",
		component: () => import(/* webpackChunkName: "order" */ "../views/Order/Order"),
		beforeEnter: isAuthenticated
	},
	{
		path: "/customers",
		name: "Customers",
		component: () => import(/* webpackChunkName: "customers" */ "../views/Customers"),
		beforeEnter: isAuthenticated
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes: routes
})

export default router
