import config from "../../config.json"

export default {
	namespaced: true,

	state: {
		orders: [],
		pages: {
			limit: 10
		}
	},

	actions: {

		/**
		 * This action is responsible for fetching data from the
		 * upstream API and then update the vuex state according
		 * to the returned data.
		 */
		list: ({ commit, state }, params) => {
			fetch(config.api.url + "/v1.0/orders?limit=" + state.pages.limit + "&page=" + params.page, {
				method: "GET",
				headers: { "Authorization": "Bearer " + localStorage.getItem("token") }
			}).then(Response => Response.json()).then(Response => {
				commit("set_orders", Response.data.orders)
			})
		}

	},

	mutations: {
		set_orders: (state, data) => { state.orders = data }
	}
}