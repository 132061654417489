import Vuex from "vuex"

// Modules
import user from "./modules/user"
import items from "./modules/items"
import orders from "./modules/orders"
import customers from "./modules/customers"

export default new Vuex.Store({
	modules: {
		user: user,
		items: items,
		customers: customers,
		orders: orders
	}
})
