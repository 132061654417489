import config from "../../config.json"

export default {
    namespaced: true,

    state: {
        customers: [],
        pages: {
            limit: 10
        }
    },

    actions: {

        /**
		 * This action is responsible for fetching data from the
		 * upstream API and then update the vuex state according
		 * to the returned data.
		 */
        list: ({ commit, state }, params) => {
            fetch(config.api.url + "/v1.0/customers?limit=" + state.pages.limit + "&page=" + params.page, {
				method: "GET",
				headers: { "Authorization": "Bearer " + localStorage.getItem("token") }
			}).then(Response => Response.json()).then(Response => {
				commit("set_customers", Response.data.customers)
			})
        }

    },

    mutations: {
        set_customers: (state, data) => { state.customers = data },
    }
}